import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import {Outlet, useNavigate} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";
import {ReactNode, useCallback} from "react";

interface MobileLayoutOverlayProps {
    title: string;
    children?: ReactNode;
}

export function MobileLayoutOverlay(props: MobileLayoutOverlayProps) {

    const navigate = useNavigate()

    const handleBackClicked = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const el = props.children || (<Outlet/>)

    return (
        <>
            <AppBar elevation={1}>
                <Toolbar
                    sx={{
                        backgroundColor: `background.paper`,
                        color: `text.primary`,
                    }}
                >
                    <IconButton onClick={handleBackClicked}>
                        <ArrowBack/>
                    </IconButton>
                    <Typography variant="h6">
                        {props.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Toolbar/>
            {el}
        </>
    )
}
