import {Typography} from "@mui/material";

export default function SplashScreenOutlet() {

    return (
        <>
            <Typography
                variant="h5"
                sx={{
                    marginBottom: '2rem',
                    fontWeight: 'bold',
                }}
            >
                What to do, today?
            </Typography>

            <Typography
                variant="subtitle1"
                sx={{
                    marginBottom: '5rem',
                }}
            >
                Triff Deine Freunde
            </Typography>
        </>
    )
}
