import React from "react";
import {OnlinePrediction, People, Settings} from "@mui/icons-material";
import W2d2dRoutes from "../../W2d2dRoutes";
import AppFwApp from "../../appfw/ui/AppFwApp";
import {Navigation} from "@toolpad/core";
import {createTheme} from "@mui/material";
import {deDE as deDeMuiLocale} from '@mui/material/locale';

const theme = createTheme(
    { },
    deDeMuiLocale,
)

export default function App() {

    // TODO: use memo?
    const branding = {
        title: 'What to do today?',
        homeUrl: W2d2dRoutes.ideas,
        logo: (<></>),
    }

    // TODO: use memo?
    const navigation: Navigation = [
        {
            segment: W2d2dRoutes.ideas.substring(1),
            // pattern: EventerRoutes.accounts.account.events.pathTemplate().substring(1),
            title: "Ideen",
            icon: <OnlinePrediction/>,
        },
        {
            segment: W2d2dRoutes.connections.substring(1),
            // pattern: EventerRoutes.accounts.account.importers.pathTemplate().substring(1),
            title: "Freunde",
            icon: <People/>,
        },
        {
            segment: W2d2dRoutes.settings.substring(1),
            // pattern: EventerRoutes.accounts.account.settings.pathTemplate().substring(1),
            title: "Einstellungen",
            icon: <Settings/>,
        },
    ]

    return (
        <AppFwApp theme={theme} branding={branding} navigation={navigation}/>
    )
}
