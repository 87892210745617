import {Fab, List} from "@mui/material";
import {Add} from "@mui/icons-material";
import {ReactElement, useCallback, useEffect, useState} from "react";
import {w2d2dApiClient} from "../../../api-client"
import ConnectionsPageEntry from "./ConnectionsPageEntry"
import {useNavigate} from "react-router-dom";
import W2d2dRoutes from "../../../../W2d2dRoutes";

const fabStyle = {
    position: 'absolute',
    bottom: 72,
    right: 16,
}

export default function ConnectionsPage() {

    const navigate = useNavigate()

    const [connections, setConnections] = useState<null | string[]>(null)

    const handleClickInvite = useCallback(async () => {
        let invitation = await w2d2dApiClient.createInvitation()

        let path = W2d2dRoutes.toInvitation(invitation.id)
        console.log("navigate to: ", path)
        navigate(W2d2dRoutes.toInvitation(invitation.id))
    }, [navigate])

    const onDelete = useCallback((connectionId: string) => {
        if (!connections) {
            return
        }
        let updatedConnections = connections.filter((c) => c !== connectionId)
        setConnections(updatedConnections)
    }, [connections, setConnections])

    useEffect(() => {
        let active = true
        w2d2dApiClient.getConnections()
            .then((response) => {
                if (active) {
                    setConnections(response.connections)
                }
            })
        return () => {
            active = false
        }
    }, [])

    let connectionsEl: ReactElement[] = []
    if (connections) {
        // TODO: use memo?
        connections.forEach((userId) => {
            connectionsEl.push(<ConnectionsPageEntry userId={userId} key={userId} onDelete={onDelete}/>)
            // connectionsEl.push(<Divider key={`${userId}-divider`} />)
        })
    }

    return (
        <>
            <List sx={{width: '100%'}}>
                {connectionsEl}
            </List>

            <Fab color="primary" aria-label="Einladen" sx={fabStyle} onClick={handleClickInvite}>
                <Add/>
            </Fab>
        </>
    )
}
