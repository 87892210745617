export interface ErrorResponse {
    readonly errors?: Error[]
}

export interface Error {
    readonly code: string
    readonly message?: string
    readonly parameters?: ErrorParameters
}

export interface ErrorParameters {
    readonly [key: string]: string
}

export enum HttpMethod {
    DELETE = "DELETE",
    GET = "GET",
    PATCH = "PATCH",
    POST = "POST",
    PUT = "PUT",
}

export interface ApiFetchOptions {
    params?: any;
    sessionId?: null | undefined | string;
    method?: HttpMethod;
}

export class ApiClient {

    constructor(public readonly baseUrl: string) {
    }

    async fetch<T>(path: string, options?: ApiFetchOptions): Promise<T> {
        const method = options ? (options.method || HttpMethod.POST) : HttpMethod.POST
        let headers = new Headers({
            'Accept': 'application/json',
        })
        if (options && options.sessionId) {
            headers.set('Authorization', `Session ${options.sessionId}`)
        }
        if (method === HttpMethod.PATCH || method === HttpMethod.POST || method === HttpMethod.PUT) {
            headers.set('Content-Type', 'application/json')
        }

        let url = `${this.baseUrl}${path}`
        // if (path.startsWith('appfw')) {
        //     // TODO: we need an own appfw client
        //     url = url.replace('/w2d2d', '')
        // }

        let response = await fetch(`${url}`, {
            headers: headers,
            method,
            body: options ? JSON.stringify(options.params) : '',
        });
        if (!response.ok) {
            // special check for 'unauthorized' with sessionId
            if (response.status === 401 && options && options.sessionId) {
                window.location.href = window.location.origin
            }
            return Promise.reject(response)
        }
        const body = await response.text();
        if (body && body.length > 0) {
            return JSON.parse(body);
        } else {
            return {} as T;
        }
    }

    async parseErrorResponse(response: Response) {

        const json = await response.json()

        let errors: Error[] = Array<Error>()
        Object.keys(json).forEach((property) => {
            if (property === 'errors') {
                let value = json[property]
                if (Array.isArray(value)) {

                    value.forEach((errorJson) => {
                        let code = errorJson["code"]
                        if (code.length === 0) {
                            // Seems to be error
                            return
                        }

                        // TODO: parse parameters

                        errors.push({
                            code: code,
                            message: errorJson["message"],
                        })
                    })
                }
            }
        })

        if (errors.length === 0) {
            errors.push(
                {
                    code: "unknown",
                }
            )
        }

        return {
            errors: errors,
        }
    }
}
