import {useUser} from "../../../api-client";
import {useMemo} from "react";
import {stringAvatar} from "../../../../appfw/users/UserUtil";
import {Avatar} from "@mui/material";

interface AvatarWidgetProps {
    id: string;
    size?: number;
}

export default function AvatarWidget(props: AvatarWidgetProps) {

    const userHook = useUser(props.id)

    const avatar = useMemo(() => {

        const sx = props.size ? { height: `${props.size}px`, width: `${props.size}px` } : null

        let user = userHook.data
        if (user) {
            if (user.name && user.avatarUrl) {
                return <Avatar alt={user?.name} src={user.avatarUrl} sx={sx} />
            } else if (user.name) {
                return <Avatar alt={user?.name} {...stringAvatar(user.name)} sx={sx} />
            }
        }

        // Nothing known about the user
        return <Avatar />

    }, [userHook])

    return avatar
}
