import {Idea, useUser, w2d2dApiClient} from "../../../api-client";
import {AvatarGroup, IconButton, ListItem, ListItemAvatar, ListItemText, Stack, Typography} from "@mui/material";
import {formatDateTime, formatTime} from "@eventer/api-client/lib/esm/utils/datetime-util";
import {ReactElement, useCallback} from "react";
import AvatarWidget from "../../components/users/AvatarWidget";
import {AddCircleOutlineOutlined, Favorite, RemoveCircleOutline} from "@mui/icons-material";
import W2d2dRoutes from "../../../../W2d2dRoutes";
import {useNavigate} from "react-router-dom";
import {User} from "../../../../appfw/api-client/AppFwApiClient";

interface IdeaPageEntryProps {
    user: User;
    idea: Idea;
    updateIdea: (idea: Idea) => void;
}

export default function IdeaPageEntry(props: IdeaPageEntryProps) {

    const navigate = useNavigate()

    const idea = props.idea
    const authorHook = useUser(idea.authorId)

    const handleEntryClick = useCallback(() => {
        navigate(W2d2dRoutes.toIdea(idea.id))
    }, [navigate])

    let isActive = idea.startsAt && new Date(idea.startsAt) < new Date() && (!idea.endsAt || new Date(idea.endsAt) > new Date())

    // TODO: badge to show an active idea?

    let startsAt = null
    if (idea.startsAt) {
        startsAt = new Date(idea.startsAt)
    }
    let endsAt = null
    if (idea.endsAt) {
        endsAt = new Date(idea.endsAt)
    }

    let startEndAtSameDay = startsAt && endsAt && startsAt.getDate() === endsAt.getDate()

    let footerText = " - "
    let hasStartsAt = false
    if (!isActive && startsAt) {
        hasStartsAt = true

        let timeSuffix = undefined
        if (startEndAtSameDay) {
            timeSuffix = null
        }

        let startsToday = startsAt.getDate() === new Date().getDate()
        if (startsToday) {
            footerText += formatTime({date: startsAt, timeSuffix: timeSuffix})
        } else {
            footerText += formatDateTime({date: startsAt})
        }
    }
    if (endsAt) {
        if (hasStartsAt) {
            footerText += " - "
        } else {
            footerText += " bis "
        }
        if (startEndAtSameDay) {
            footerText += formatTime({date: new Date(endsAt)})
        } else {
            footerText += formatDateTime({date: new Date(endsAt)})
        }
    }


    const groupAvatarSize = 24
    const groupSx = { marginLeft: 0, marginTop: '4px', marginRight: '2px' }

    let acceptedGroupEl: ReactElement[] = []
    idea.accepted?.forEach((userId) => {
        acceptedGroupEl.push(<AvatarWidget id={userId} key={userId} size={groupAvatarSize}/>)
    })

    let acceptedGroupEl2 = (
        <AvatarGroup sx={groupSx}>
            {acceptedGroupEl}
        </AvatarGroup>
    )

    let interestedGroupEl: ReactElement[] = []
    idea.interested?.forEach((userId) => {
        interestedGroupEl.push(<AvatarWidget id={userId} key={userId} size={groupAvatarSize}/>)
    })
    let interestedGroupEl2 = (
        <AvatarGroup sx={groupSx}>
            {interestedGroupEl}
        </AvatarGroup>
    )

    let rejectedGroupEl: ReactElement[] = []
    idea.rejected?.forEach((userId) => {
        rejectedGroupEl.push(<AvatarWidget id={userId} key={userId} size={groupAvatarSize}/>)
    })
    let rejectedGroupEl2 = (
        <AvatarGroup sx={groupSx}>
            {rejectedGroupEl}
        </AvatarGroup>
    )

    const handleClickAccept = useCallback(async () => {
        let updatedIdea
        if (idea.accepted?.includes(props.user.id)) {
            updatedIdea = await w2d2dApiClient.deleteIdeaParticipation({id: idea.id})
        } else {
            updatedIdea = await w2d2dApiClient.acceptIdea({id: idea.id})
        }
        props.updateIdea(updatedIdea)
    }, [idea.id, props.updateIdea])

    const handleClickInterested = useCallback(async () => {
        let updatedIdea
        if (idea.interested?.includes(props.user.id)) {
            updatedIdea = await w2d2dApiClient.deleteIdeaParticipation({id: idea.id})
        } else {
            updatedIdea = await w2d2dApiClient.interestedInIdea({id: idea.id})
        }
        props.updateIdea(updatedIdea)
    }, [idea.id, props.updateIdea])

    const handleClickReject = useCallback(async () => {
        let updatedIdea
        if (idea.rejected?.includes(props.user.id)) {
            updatedIdea = await w2d2dApiClient.deleteIdeaParticipation({id: idea.id})
        } else {
            updatedIdea = await w2d2dApiClient.rejectIdea({id: idea.id})
        }
        props.updateIdea(updatedIdea)
    }, [idea.id, props.updateIdea])

    return (
        <ListItem alignItems="flex-start" sx={{ alignItems: 'center' }}>
            <ListItemAvatar
                onClick={handleEntryClick}
            >
                <AvatarWidget id={idea.authorId} size={64}/>
            </ListItemAvatar>
            <ListItemText
                primary={idea.title}
                onClick={handleEntryClick}
                secondary={
                    <>
                        <Typography
                            component="span"
                            variant="body2"
                            sx={{color: 'text.primary', display: 'inline'}}
                        >
                            {authorHook.data?.name}
                        </Typography>
                        {footerText}
                    </>
                }
                sx={{ marginLeft: '8px' }}
            />

            <Stack direction="column" spacing={0}>

                <Stack direction="row" spacing={0} sx={{ justifyContent: 'right' }}>
                    {acceptedGroupEl2}
                    <IconButton aria-label="Dabei" size="small" onClick={handleClickAccept}>
                        <AddCircleOutlineOutlined/>
                    </IconButton>
                </Stack>

                <Stack direction="row" spacing={0} sx={{ justifyContent: 'right' }}>
                    {interestedGroupEl2}
                    <IconButton aria-label="Interessiert" size="small" onClick={handleClickInterested}>
                        <Favorite/>
                    </IconButton>
                </Stack>

                <Stack direction="row" spacing={0} sx={{ justifyContent: 'right' }}>
                    {rejectedGroupEl2}
                    <IconButton aria-label="Nicht dabei" size="small" onClick={handleClickReject}>
                        <RemoveCircleOutline/>
                    </IconButton>
                </Stack>

            </Stack>

        </ListItem>
    )
}
