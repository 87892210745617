import {Divider, List, ListItem, ListItemIcon, ListItemText, Switch} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {Logout, Notifications} from "@mui/icons-material";
import useNotifications from "../../../../appfw/notifications/useNotifications";
import {useNavigate} from "react-router-dom";
import W2d2dRoutes from "../../../../W2d2dRoutes";
import Session from "../../../../appfw/authentication/Session";
import {useSession} from "@toolpad/core";

export default function SettingsPage() {

    const navigate = useNavigate()
    const session = useSession<Session>()
    const notifications = useNotifications()

    const [pushEnabled, setPushEnabled] = useState<undefined | boolean>(undefined)

    const handleNotificationsSwitchChange = useCallback(async () => {
        if (notifications.pushEnabled && Notification.permission === 'granted') {
            await notifications.unsubscribe(true)
        } else {
            setPushEnabled(undefined) // ==> "loading transition"
            await notifications.subscribe()
        }
    }, [notifications, setPushEnabled])

    const handleLogoutClick = useCallback(() => {
        session?.logout()
        navigate(W2d2dRoutes._ROOT)
        return
    }, [session])

    useEffect(() => {
        console.log("SettingsPage: useEffect called")

        if (notifications.pushEnabled === undefined) {
            setPushEnabled(undefined)
        } else {
            setPushEnabled(notifications.pushEnabled && Notification.permission === 'granted')
        }
    }, [notifications.pushEnabled, setPushEnabled])

    return (
        <>
            <List sx={{width: '100%'}}>
                <ListItem>
                    <ListItemIcon>
                        <Notifications/>
                    </ListItemIcon>
                    <ListItemText
                        primary="Benachrichtigungen"
                        secondary="Erlaube uns, Dich über wichtige Ereignisse zu informieren.">
                    </ListItemText>
                    <Switch
                        disabled={pushEnabled === undefined}
                        checked={pushEnabled == true}
                        onChange={handleNotificationsSwitchChange}
                    >
                    </Switch>
                </ListItem>
                <Divider/>
                <ListItem onClick={handleLogoutClick} sx={{cursor: "pointer"}}>
                    <ListItemIcon>
                        <Logout/>
                    </ListItemIcon>
                    <ListItemText primary="Abmelden"/>
                </ListItem>
            </List>
        </>
    )
}
