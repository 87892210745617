import {Checkbox, ListItemText, MenuItem, Stack} from "@mui/material";
import {useCallback, useState} from "react";
import {useUsers} from "../../../api-client";
import AvatarWidget from "./AvatarWidget";

interface UsersChooserProps {
    readonly userIds: string[];
    readonly selectedUserIds: string[];
    readonly onSelectionChange: (selectedUserIds: string[]) => void;
}

export default function UsersChooser(props: UsersChooserProps) {

    const usersHook = useUsers(props.userIds)

    const onUserClick = useCallback((userId: string) => {
        let newSelection = props.selectedUserIds.includes(userId)
            ? props.selectedUserIds.filter((id) => id !== userId)
            : [...props.selectedUserIds, userId]
        props.onSelectionChange(newSelection)
    }, [props.selectedUserIds, props.onSelectionChange])

    return (
        <>
            {
                usersHook.map((user) => {
                    let userId = user.data?.id
                    if (userId === undefined) {
                        return
                    }
                    return (
                        <MenuItem
                            key={`connection-${userId}`}
                            onClick={() => onUserClick(userId)}
                        >
                            <Stack direction="row" alignItems="center">
                                <Checkbox checked={props.selectedUserIds.includes(userId)} />
                                <AvatarWidget id={userId} size={24}/>
                                <ListItemText
                                    primary={user.data?.name}
                                    sx={{
                                        marginLeft: '8px',
                                    }}
                                />
                            </Stack>
                        </MenuItem>
                    )
                })
            }
        </>
    )
}
