import {BottomNavigation, BottomNavigationAction, Box, Paper} from "@mui/material";
import React, {useEffect, useMemo} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {NavigationPageItem} from "@toolpad/core/AppProvider/AppProvider";
import {NavigationContext} from "@toolpad/core/shared/context";

function isNavigationPageItem(object: any): object is NavigationPageItem {
    return 'segment' in object;
}

export default function MobileLayout() {

    const navigationContext = React.useContext(NavigationContext)

    const navigate = useNavigate()
    const [activeSection, setActiveSection] = React.useState<undefined | NavigationPageItem>()

    const actions = useMemo(() => {
        const navigationPageItems = navigationContext.filter(isNavigationPageItem)
        console.log("navigationPageItems", navigationPageItems)
        return navigationPageItems.map(navigationItem => {
            return <BottomNavigationAction
                key={navigationItem.segment}
                label={navigationItem.title}
                value={navigationItem}
                icon={navigationItem.icon}
                onClick={() => navigate(navigationItem.segment!!)}
            />
        })
    }, [navigationContext])

    useEffect(() => {
        let path: null | string = location.pathname
        const navigationPageItems = navigationContext.filter(isNavigationPageItem)
        const activeSectionPath = navigationPageItems
            .find((navigationItem) => {
                return (navigationItem.segment && path?.startsWith(`/${navigationItem.segment}`))
            })
        if (activeSectionPath) {
            setActiveSection(activeSectionPath as NavigationPageItem)
        }
    }, [navigationContext])

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Outlet/>
            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={activeSection}
                    onChange={(_: any, newValue: React.SetStateAction<string | undefined>) => {
                        setActiveSection(newValue as NavigationPageItem);
                    }}
                >
                    {actions}
                </BottomNavigation>
            </Paper>
        </Box>
    )
}
