import React from 'react'
import {createRoot} from 'react-dom/client'
import {Navigate, Route, Routes} from "react-router-dom";
import App from "./wd2d2/app/App";
import IdeasPage from "./wd2d2/app/pages/ideas/IdeasPage";
import W2d2dRoutes from "./W2d2dRoutes";
import RootElementWrapper from "./appfw/RootElementWrapper";
import AuthenticationGuard from "./appfw/authentication/AuthenticationGuard";
import SplashScreenOutlet from "./wd2d2/app/SplashScreenOutlet";
import {MobileLayoutOverlay} from "./appfw/ui/layouts/mobile/MobileLayoutOverlay";
import EditIdeaPage from "./wd2d2/app/pages/ideas/edit/EditIdeaPage";
import IdeaPage from "./wd2d2/app/pages/ideas/IdeaPage";
import EditIdeaRoute from "./wd2d2/app/pages/ideas/edit/EditIdeaRoute";
import MobileLayout from "./appfw/ui/layouts/mobile/MobileLayout";
import JoinIdeaRoute from "./wd2d2/app/pages/ideas/join/JoinIdeaRoute";
import ConnectionsPage from "./wd2d2/app/pages/connections/ConnectionsPage";
import InvitationPage from "./wd2d2/app/pages/invitations/InvitationPage";
import SettingsPage from "./wd2d2/app/pages/settings/SettingsPage";

let AppRoutes =
    <Routes>
        <Route element={<App/>}>
            <Route element={<MobileLayout/>}>

                <Route path={W2d2dRoutes.ideas}>
                    <Route index element={<IdeasPage/>}/>
                    <Route path="add" element={<MobileLayoutOverlay title="Idee vorschlagen"/>}>
                        <Route index element={<EditIdeaPage/>}/>
                    </Route>
                    <Route path=":id">
                        <Route index element={
                            <MobileLayoutOverlay title="Idee">
                                <IdeaPage/>
                            </MobileLayoutOverlay>
                        }/>
                        <Route path="edit" element={<EditIdeaRoute/>}/>
                        <Route path="join/:token" element={
                            <MobileLayoutOverlay title="Idee">
                                <JoinIdeaRoute/>
                            </MobileLayoutOverlay>
                        }/>
                    </Route>
                </Route>

                <Route path={W2d2dRoutes.connections} element={<ConnectionsPage/>}/>

                <Route path={W2d2dRoutes.invitations}>
                    <Route path=":id" element={<MobileLayoutOverlay title="Einladung"/>}>
                        <Route index element={<InvitationPage/>}/>
                    </Route>
                </Route>

                <Route path={W2d2dRoutes.settings} element={<SettingsPage/>}/>
            </Route>
        </Route>

        {/* Global not found route => general 404 page + specific 404 pages */}
        <Route path="*" element={<Navigate to={W2d2dRoutes.ideas} replace/>}/>
    </Routes>


const node = document.getElementById('root') as HTMLElement
createRoot(node).render(
    <RootElementWrapper>
        <AuthenticationGuard
            authenticationConfig={{app: "w2d2d", oidcProviders: ["google", "apple"]}}
            splashScreenOutlet={<SplashScreenOutlet/>}
        >
            {AppRoutes}
        </AuthenticationGuard>
    </RootElementWrapper>
)